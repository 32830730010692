<!-- anchor -->
<template>
  <el-select
    class="anchor"
    v-model="selected"
    :placeholder="placeholder"
    :disabled="disabled"
    :clearable="clearable"
    :filterable="filterable"
    :filter-method="filterMethod"
    :default-first-option="defaultFirstOption"
    :size="size"
    remote
    :remote-method="getAnchorsFuzzy"
    :loading="loading"
    :popper-append-to-body="false"
    :debounce="debounce"
    @change="handleValueChange"
    @clear="handleValueClear"
  >
    <el-option
      v-for="(item, index) in list"
      :label="item[labelKey]"
      :value="item[valueKey]"
      :key="item[valueKey]"
    >
      <slot v-if="$scopedSlots.default" :item="item" :index="index" />

      <div v-else class="option-item">
        <div class="item-container">
          <span class="item-label">主播名称:</span>
          <span class="item-value">{{ item.name }}</span>
        </div>

        <div class="item-container">
          <span class="item-label">直播平台:</span>
          <span class="item-value">{{ item.platformName }}</span>
        </div>

        <div class="item-container">
          <span class="item-label">直播间号:</span>
          <span class="item-value">{{ item.roomNo }}</span>
        </div>

        <div class="item-container">
          <span class="item-label">游戏板块:</span>
          <span class="item-value">{{ item.categoryName }}</span>
        </div>
      </div>
    </el-option>
  </el-select>
</template>

<script>
  import select from '../../mixins/select';
  import anchorStatus from '../../enum/anchor-status';
  import { getAnchors } from '../../api/common';

  export default {
    name: 'anchor',
    mixins: [select],
    props: {
      // 搜索防抖时长，单位: ms，默认: 500
      debounce: {
        type: Number,
        default: 500,
      },
      // 主播状态，默认: 全部，可选: '跟进中'、'闲置中(待签约)'、'合作中'
      status: {
        type: [Number, Array],
        validator: val => {
          const statusArr = Array.isArray(val) ? val : [val];
          const statusMap = anchorStatus.map;

          return !statusArr.some(status => !statusMap.hasOwnProperty(status));
        },
        default: () => Object.values(anchorStatus.enum),
      },
      // 限制个数，默认: 5
      limit: {
        type: Number,
        default: 5,
      },
    },
    data() {
      return {
        loading: false,
      };
    },
    mounted() {
      this.getAnchorsFuzzy();
    },
    methods: {
      async getAnchorsFuzzy(keyword) {
        const status = this.status;
        const params = {
          status: Array.isArray(status) ? status.join() : status,
          keyword,
          limit: this.limit,
        };

        this.loading = true;

        const res = await getAnchors(params);

        this.loading = false;

        if (!res) {
          this.list = [];

          return;
        }

        const { data: anchors = [] } = res;

        this.list = anchors.map(item => {
          const { _id, name } = item;

          return {
            ...item,
            [this.labelKey]: name,
            [this.valueKey]: _id,
          };
        });

        // 如果开启了默认选中第一项，则选中第一项
        this.defaultSelectFirst && this.selectFirstItem();
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../style/mixin.scss';

  .anchor {
    /deep/ .el-select-dropdown .el-select-dropdown__item {
      height: auto;

      .option-item {
        @include clearfix();

        width: 420px;
        padding: 5px 0;
        line-height: 25px;
        font-size: 12px;

        .item-container {
          float: left;
          width: 50%;
          padding-right: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .item-label,
          .item-value {
            display: inline-block;
          }

          .item-label {
            width: 60px;
            color: #999;
          }
        }
      }

      & + .el-select-dropdown__item .option-item {
        border-top: 1px dashed #eee;
      }
    }
  }
</style>
