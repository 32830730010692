<!-- sign-edit -->
<template>
  <el-dialog
    class="sign-edit"
    :visible.sync="open"
    :title="title"
    width="560px"
    :close-on-click-modal="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <el-form
      class="sign-edit-form"
      ref="form"
      v-loading="loading"
      :model="model"
      :rules="rules"
      size="small"
      label-width="85px"
      @submit.native.prevent
    >
      <el-form-item label="主播名称:" prop="anchorId">
        <!-- 编辑签约 -->
        <template v-if="!isEdit">
          <anchor
            v-if="open"
            class="form-anchor"
            v-model="model.anchorId"
            :status="anchorStatusEnum.idling"
          />
        </template>

        <!-- 新增签约 -->
        <template v-else>
          <el-input v-model="model.anchorName" disabled />
        </template>
      </el-form-item>

      <el-form-item label="游戏名称:" prop="gameId">
        <game-name v-model="model.gameId" />
      </el-form-item>

      <el-form-item label="签约天数:" prop="days">
        <el-input v-model.number="model.days" placeholder="请输入" clearable>
          <template v-slot:append>天</template>
        </el-input>
      </el-form-item>

      <el-form-item label="支付方式:" prop="payment">
        <payment-type v-model="model.payment" />
      </el-form-item>

      <el-form-item
        v-if="model.payment !== paymentTypeEnum.cps"
        label="CPT价位:"
        prop="priceCPT"
      >
        <el-input
          v-model.number="model.priceCPT"
          placeholder="请输入"
          clearable
        >
          <template v-slot:append>元/天</template>
        </el-input>
      </el-form-item>

      <el-form-item
        v-if="model.payment !== paymentTypeEnum.cpt"
        label="CPS价位:"
        prop="priceCPS"
      >
        <el-input
          v-model.number="model.priceCPS"
          placeholder="请输入"
          clearable
        >
          <template v-slot:append>%</template>
        </el-input>
      </el-form-item>

      <el-form-item label="申请预算:" prop="budget">
        <el-input v-model.number="model.budget" placeholder="请输入" clearable>
          <template v-slot:append>元</template>
        </el-input>
      </el-form-item>

      <el-form-item label="是否挂码:" prop="qrcode">
        <el-radio-group v-model="model.qrcode">
          <el-radio
            v-for="item in qrcodeTypeList"
            :label="item.value"
            :key="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
    </el-form>

    <template v-slot:footer>
      <div class="sign-edit-operate">
        <el-button size="small" @click="handleOperateCancelClick"
          >取消
        </el-button>

        <el-button
          type="primary"
          size="small"
          :loading="saving"
          @click="handleOperateOkClick"
          >确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import { mapState } from 'vuex';
  import Anchor from '../../../../components/anchor';
  import GameName from '../../../../components/game-name';
  import PaymentType from '../../../../components/payment-type';
  import anchorStatus from '../../../../enum/anchor-status';
  import paymentType from '../../../../enum/payment-type.js';
  import qrcodeType from '../../../../enum/qrcode-type';
  import { format } from '../../../../util/date.js';
  import {
    isPositiveIntString,
    isNonNegativeIntString,
  } from '../../../../util/validator.js';
  import { getSign, addSign, updateSign } from '../../../../api/anchor/sign';

  // 签约天数校验
  const daysValidator = (rule, value, callback) => {
    !isPositiveIntString(value)
      ? callback(new Error('请填写正整数'))
      : callback();
  };
  // CPT价位校验
  const cptValidator = (rule, value, callback) => {
    !isNonNegativeIntString(value)
      ? callback(new Error('请填写非负整数'))
      : callback();
  };
  // CPS校验
  const cpsValidator = (rule, value, callback) => {
    if (!isNonNegativeIntString(value)) {
      callback(new Error('请填写非负整数'));

      return;
    }

    +value > 100 ? callback(new Error('请填写0到100之间的整数')) : callback();
  };

  export default {
    name: 'sign-edit',
    components: {
      Anchor,
      GameName,
      PaymentType,
    },
    props: {
      // 是否开启对话框
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        // 当前编辑的签约对象
        sign: null,
        model: null,
        rules: {
          anchorId: [{ required: true, message: '请选择', trigger: 'change' }],
          gameId: [{ required: true, message: '请选择', trigger: 'change' }],
          days: [
            { required: true, message: '请填写', trigger: 'change' },
            { validator: daysValidator, trigger: 'change' },
          ],
          payment: [{ required: true, message: '请选择', trigger: 'change' }],
          priceCPT: [
            { required: true, message: '请填写', trigger: 'change' },
            { validator: cptValidator, trigger: 'change' },
          ],
          priceCPS: [
            { required: true, message: '请填写', trigger: 'change' },
            { validator: cpsValidator, trigger: 'change' },
          ],
          budget: [
            { required: true, message: '请填写', trigger: 'change' },
            { validator: cptValidator, trigger: 'change' },
          ],
          qrcode: [{ required: true, message: '请选择', trigger: 'change' }],
        },
        loading: false,
        saving: false,
      };
    },
    computed: {
      ...mapState('anchor/sign', ['signId']),

      open: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      // 是否是编辑
      isEdit() {
        return !!this.signId;
      },
      // 对话框标题
      title() {
        return `${this.isEdit ? '编辑' : '新增'}签约`;
      },
      // 主播状态枚举值
      anchorStatusEnum() {
        return anchorStatus.enum;
      },
      // 支付方式枚举值
      paymentTypeEnum() {
        return paymentType.enum;
      },
      // 是否挂码列表
      qrcodeTypeList() {
        const { map } = qrcodeType;

        return Object.entries(map).map(([value, key]) => ({
          label: key,
          value: +value,
        }));
      },
    },
    created() {
      this.initModel();
    },
    methods: {
      // 初始化模型
      initModel(data = {}) {
        const {
          anchorId = '',
          anchorName = '',
          gameId = '',
          days = '',
          payment = paymentType.enum.cpt,
          priceCPT = '',
          priceCPS = '',
          budget = '',
          qrcode = qrcodeType.enum.no,
        } = data || {};

        this.model = {
          anchorId,
          anchorName,
          gameId,
          days,
          payment,
          priceCPT,
          priceCPS,
          budget,
          qrcode,
        };
      },
      // 校验模型
      async validateModel() {
        try {
          return await this.$refs.form.validate();
        } catch (e) {
          return false;
        }
      },
      // 重置模型
      resetModel() {
        this.initModel();
        this.$refs.form.resetFields();
      },
      // 保存模型
      async saveModel() {
        const params = {
          ...this.model,
          id: this.signId,
        };
        const method = this.isEdit ? updateSign : addSign;
        const res = await method(params);

        return !!res;
      },
      // 获取签约对象详情
      async getSign() {
        const res = await getSign(this.signId);

        if (!res) return false;

        this.sign = res.data;

        return true;
      },
      // 对话框打开
      async handleDialogOpen() {
        if (!this.isEdit) return;

        this.loading = true;

        const success = await this.getSign();

        this.loading = false;

        if (!success) return;

        this.initModel(this.sign);
      },
      // 对话框关闭完成
      handleDialogClosed() {
        this.sign = null;

        this.resetModel();
      },
      // 对话框取消按钮单击
      handleOperateCancelClick() {
        this.open = false;
      },
      // 对话框确定按钮单击
      async handleOperateOkClick() {
        const isValid = await this.validateModel();

        if (!isValid) return;

        this.saving = true;

        const success = await this.saveModel();

        this.saving = false;

        if (!success) return;

        this.open = false;
        this.$emit('on-ok');
      },
    },
  };
</script>

<style scoped lang="scss">
  .sign-edit {
    .sign-edit-form {
      .el-select,
      .el-input {
        width: 100%;
      }
    }
  }
</style>
